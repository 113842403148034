import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { getContractType } from "./query.js";
import { useParams } from "react-router-dom";
import { Main } from "../common/Main/index.js";
import { DateableDetail } from "../common/DateableDetail/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import {
    isUndefinedOrNull,
    hasOneOf,
    formatTimeValue,
    isoDate 
} from "../../utils/functions.js";
import { NoPermission } from "../common/NoPermission/index.js";
import {
    MODIFY_CONTRACT_TYPE,
    CREATE_CONTRACT_TYPE_VERSION,
    MODIFY_CONTRACT_TYPE_VERSION
} from "../../constants/mutations.js";
import { getGenuineModifications, handleGraphQLError, handleMutation } from "../../utils/errorHandling.js";
import { ContractTable } from "./contractTable.js";
import { SubmissionTypesTable } from "./submissionTypesTable.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import dayjs from "dayjs";

const VALID_MODIFIED_FIELDS = [
    "startDate",
    "endDate",
    "name",
    "specifier",
    "description"
];

const makeSearchFilter = (searchText, key) => {
    const shouldFilter = searchText && typeof searchText === "string"; 
    return shouldFilter 
        ? (item) => item[key].toLowerCase().includes(searchText.toLowerCase()) 
        : null; 
}; 


const applyFilters = (arr, filters = []) => {
    const filtersToUse = filters.filter(Boolean);
    return filtersToUse.length > 0 
        ? arr.filter(item => filtersToUse.every(filter => filter(item))) 
        : arr;
}; 

const getLatestVersion = (versions) => {
    if (Array.isArray(versions)) {
        const latestVersion = versions[versions.length - 1];
        return latestVersion && typeof latestVersion === "object" ? latestVersion : undefined;
    } else {
        return undefined;
    }
};

const getVersionDisplayText = (specifier, createdAt) => {
    return parseInt(specifier, 10) === 0
        ? `Initial Plan (${formatTimeValue(createdAt)})`
        : `Amendment  ${specifier} (${formatTimeValue(createdAt)})`;
};


export const ContractTypeDetail = ( ) => {
    // STATE 
    const params = useParams();
    const { preloadedData } = usePreloadedData();

    const [modified, setModified] = useState({});
    const [editingSubmissionTypes, setEditingSubmissionTypes] = useState(false);
    const [displayedContractTypeVersion, setDisplayedVersion] = useState({});
    const [showLoading, setShowLoading] = useState(false);

    // MUTATIONS 
    const [modifyContractType] = useMutation(MODIFY_CONTRACT_TYPE);
    const [modifyContractTypeVersion] = useMutation(MODIFY_CONTRACT_TYPE_VERSION);
    const [createContractTypeVersion] = useMutation(CREATE_CONTRACT_TYPE_VERSION);

    const {
        userPermViewContractTypes,
        userPermCreateContractTypes,
        userPermCreateSubmissionType,
        userPermViewUserAuditLog
    } = useContext(UserContext);

    const { data, loading, error, refetch } = useQuery(
        getContractType,
        {
            variables: {
                id: params.id,
                contractTypesFilter: [params.id]
            },
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        setModified({});
        setEditingSubmissionTypes(false);
    }, [displayedContractTypeVersion]);

    if (!userPermViewContractTypes) {
        return <NoPermission />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(data.contractType)) {
        return <NoContent />;
    }

    const handleDataUpdate = (afterUpdate, shouldSetToLatestVersion = true) => {
        refetch().then(response => {
            if (shouldSetToLatestVersion) {
                const newVersion = getLatestVersion(response?.data?.contractType?.contractTypeVersions);
                setDisplayedVersion(newVersion ? newVersion : {});
            } else {
                const updatedDisplayVersion = response?.data?.contractType?.contractTypeVersions?.find(({ id }) => id === displayedContractTypeVersion?.id);
                setDisplayedVersion(updatedDisplayVersion ? updatedDisplayVersion : {});
            }
        }).finally(() => {
            if (typeof afterUpdate === "function") {
                afterUpdate();
            }
        });
    };

    const contractType = { ...data.contractType };
    const latestVersion = getLatestVersion(contractType.contractTypeVersions);
    const organizations = preloadedData.submitterOrganizations;
    const amendments = contractType?.contractTypeVersions ?? [];
    const shouldListAmendments = latestVersion && latestVersion.specifier > 0;
    const newBreadcrumb = { 
        label: contractType.name, 
        path: `/contract-types/${contractType.id}`, 
        tooltip: `Contract Type: ${contractType.name}`
    };    
    
    // setting default values in the details :: 
    if (!displayedContractTypeVersion?.id) {
        setDisplayedVersion(latestVersion);
    }

    const versionSelection = !shouldListAmendments ? "" : (
        <SelectableDetail
            title="Amendment"
            passedKey="contract-type-amendment-selection"
            options={amendments.map(({
                id,
                specifier,
                createdAt
            }) => {
                return {
                    id,
                    text: getVersionDisplayText(specifier, createdAt),
                    value: id
                };
            })}
            strict={true}
            value={displayedContractTypeVersion.id}
            onValueUpdated={(versionId) => {
                const versionToDisplay = contractType.contractTypeVersions.find(v => v.id === versionId) ?? {};
                setDisplayedVersion(() => versionToDisplay);
                setModified({});
            }}
        />
    );

    const saveButtonEnabled = hasOneOf(modified, "name", "specifier", "description", "startDate", "endDate");

    const details = (
        <div id="details">
            
            { userPermViewUserAuditLog && contractType.id && 
                <div style={{margin: "10px 0px", fontSize: "16px"}}>
                    <PCDULink 
                        to={`/changelogs/contract-type-plans/${contractType.id}`}
                        newBreadcrumbs={newBreadcrumb}
                    > 
                        Changelogs 
                    </PCDULink>
                </div>
            }
            
            <EditableDetail
                title={"Name"}
                value={modified?.name ? modified.name : contractType.name}
                strict={true}
                readOnly={!userPermCreateContractTypes || editingSubmissionTypes}
                onValueUpdated={(e) => {
                    setModified({
                        ...modified,
                        name: e.target.value
                    });
                }}
            />

            <EditableDetail
                title={"ID"}
                value={modified?.specifier ? modified.specifier : contractType.specifier}
                strict={true}
                readOnly={!userPermCreateContractTypes || editingSubmissionTypes}
                onValueUpdated={(e) => {
                    setModified({
                        ...modified,
                        specifier: e.target.value
                    });
                }}
            />

            {versionSelection}

            <DateableDetail
                title="Start Date"
                key="startDate"
                readOnly={!userPermCreateContractTypes || editingSubmissionTypes}
                value={dayjs(moment(modified?.startDate ? modified.startDate : displayedContractTypeVersion.startDate))}
                onValueUpdated={(value) => {
                    setModified({
                        ...modified,
                        startDate: isoDate(value)
                    });
                }}
            />

            <DateableDetail
                title="End Date"
                key="endDate"
                readOnly={!userPermCreateContractTypes || editingSubmissionTypes}
                value={dayjs(moment(modified?.endDate ? modified.endDate : displayedContractTypeVersion.endDate))}
                disabledDate={(selectedDate) => {
                    const currentDate = moment().endOf("day");
                    const startDate = moment(modified?.startDate ? modified.startDate : displayedContractTypeVersion.startDate).endOf("day");
                    if (startDate && startDate > currentDate) {
                        return selectedDate && selectedDate < startDate;
                    } else {
                        return selectedDate && selectedDate < currentDate;
                    } 
                }}
                onValueUpdated={(value) => {
                    setModified({
                        ...modified,
                        endDate: isoDate(value)
                    });
                }}
            />

            { saveButtonEnabled 
                ? <Button
                    type="primary"
                    disabled={modified.createNewAmendment}
                    loading={showLoading}
                    onClick={async () => {
                        setShowLoading(true);
                        setModified({
                            ...modified,
                            saveChangestoCurrentPlan: true
                        });
                        const isEditingCT = hasOneOf(modified, "name", "specifier", "description");
                        const isEditingVersion = modified.startDate || modified.endDate;

                        if (isEditingCT) {
                            // suppress error message if user is making changes to version : 
                            const noMessage = isEditingVersion;

                            const genuineModifictions = getGenuineModifications( { ...modified }, contractType, {
                                limitTo: ["name", "specifier", "description"],
                                trim: true,
                                noMessage
                            });
                            
                            if (genuineModifictions) {
                                const modifyCT = modifyContractType({
                                    variables: {
                                        id: contractType.id,
                                        contractType: genuineModifictions
                                    }
                                });
                                
                                await handleMutation(modifyCT, {
                                    showSuccess: true
                                });
                            }
                        }

                        if (isEditingVersion) {
                            const modifyVersion = modifyContractTypeVersion({
                                variables: {
                                    contractType: {
                                        id: contractType?.id,
                                        versionId: displayedContractTypeVersion?.id,
                                        startDate: (modified?.startDate) ? modified.startDate : displayedContractTypeVersion?.startDate,
                                        endDate: (modified?.endDate) ? modified.endDate : displayedContractTypeVersion?.endDate
                                    }
                                }
                            });
                            
                            await handleMutation(modifyVersion, {
                                showSuccess: true
                            });
                        }

                        handleDataUpdate(() => {
                            setShowLoading(false);
                            setModified({});
                        }, false);
                    }}
                >
                    Save Changes to Current Plan
                </Button>
                : null
            }
            {
                hasOneOf(modified, "startDate", "endDate")
                    ? <Button
                        className="spaceBetween-sm"
                        type="primary"
                        disabled={modified.saveChangestoCurrentPlan}
                        loading={showLoading}

                        onClick={async () => {
                            setShowLoading(true);
                            setModified({
                                ...modified,
                                createNewAmendment: true
                            });

                            const mutationInput = {
                                variables: {
                                    contractType: {
                                        id: contractType.id,
                                        versionSpecifier: displayedContractTypeVersion?.specifier,
                                        startDate: (modified.startDate) ? modified.startDate : displayedContractTypeVersion?.startDate,
                                        endDate: (modified.endDate) ? modified.endDate : displayedContractTypeVersion?.endDate
                                    },
                                    withSubmissionTypesOfVersionId: displayedContractTypeVersion?.id
                                }
                            };

                            await handleMutation(
                                createContractTypeVersion(mutationInput)
                            );

                            handleDataUpdate(() => {
                                setShowLoading(false);
                                setModified({});
                            });
                        }}
                    >
                        Create New Amendment
                    </Button>
                    : null
            }
            {
                hasOneOf(modified, VALID_MODIFIED_FIELDS)
                    ? <Button
                        style={{ display: userPermCreateContractTypes ? "inline-block" : "none" }}
                        id="startEditing"
                        className="spaceBetween-sm"
                        type="primary"
                        onClick={() => {
                            setModified({});
                        }}
                        disabled={showLoading}
                    >
                        Cancel
                    </Button>
                    : null
            }
        </div>
    );

    return (
        <Main
            title={contractType.name}
            details={details}
            breadcrumbs={[
                <PCDULink to="/contract-types" tooltip={"Contract Types"}>Contract Types</PCDULink>,
                <span>{contractType?.name}</span>
            ]}
        >
            <SubmissionTypesTable
                newBreadcrumb={newBreadcrumb}
                userPermCreateSubmissionType={userPermCreateSubmissionType}
                editingSubmissionTypes={editingSubmissionTypes}
                setEditingSubmissionTypes={setEditingSubmissionTypes}
                applyFilters={applyFilters}
                submissionTypes={data?.submissionTypes}
                displayedContractTypeVersion={displayedContractTypeVersion}
                displayedVersionText={getVersionDisplayText(displayedContractTypeVersion.specifier, displayedContractTypeVersion.createdAt)}
                contractType={contractType}
                userPermCreateContractTypes={userPermCreateContractTypes}
                clearOut={() => setModified({})}
                handleDataUpdate={handleDataUpdate}
            />
            <ContractTable
                newBreadcrumb={newBreadcrumb}
                organizations={organizations}
                applyFilters={applyFilters}
                contractsList={data?.contractsList}
                makeSearchFilter={makeSearchFilter}
            />
        </Main>
    );
};
import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Radio, Modal } from "antd";
import { Table } from "../../common/Table/index.js";
import { useParams } from "react-router-dom";
import { UserContext } from "../../Application/UserContext.js";
import { Main } from "../../common/Main/index.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { NoPermission } from "../../common/NoPermission/index.js";
import { NoContent } from "../../common/NoContent/index.js";
import { EditableDetail } from "../../common/EditableDetail/index.js";
import { CREATE_CONTRACT_TYPE_CATEGORY, MODIFY_CONTRACT_TYPE_CATEGORY } from "../../../constants/mutations.js";
import { handleGraphQLError, handleMutations, handleMutation, getGenuineModifications } from "../../../utils/errorHandling.js";
import safeSort from "../../../utils/safeTableSorter.js";
import { librarySettingsQuery } from "../queries.js";

const { confirm } = Modal;

export const LibrarySettings = () => {
    const params = useParams();
    const { contractTypeId } = params; 
    const [active, setActive] = useState(true);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [createCategoryModal, setCreateCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [modifiedCategoryOldName, setModifiedCategoryOldName] = useState("");
    const [modifiedCategoryName, setModifiedCategoryName] = useState(null);
    const [modifiedCategoryId, setModifiedCategoryId] = useState("");
    const [createContractTypeCategory] = useMutation(CREATE_CONTRACT_TYPE_CATEGORY);
    const [modifyContractTypeCategory] = useMutation(MODIFY_CONTRACT_TYPE_CATEGORY);
    const { userPermCreateContractTypeCategory } = useContext(UserContext);

    const { data, loading, error, refetch } = useQuery(
        librarySettingsQuery, {
            variables: { 
                id: contractTypeId 
            }
        }
    );

    const contractType = data?.contractType;
    const name = contractType?.name;

    useEffect(() => {
        // when switching to different settings page, reset active / archive view to default :: 
        if (name) {
            setActive(true);
        }
    }, [name]);

    if (loading) {
        return <LoadingContent />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (!userPermCreateContractTypeCategory) {
        return <NoPermission />;
    }

    if (!contractType || typeof contractType !== "object" || contractType?.deleted) {
        return <NoContent />;
    }

    const categories = contractType?.categories?.filter?.(category => active ? !category.deleted : category.deleted) ?? [];
    const breadcrumbs = ["Library Documents", "Contract Type Settings"];

    if (name) {
        breadcrumbs.push(name);
    }

    const showConfirmArchive = () => {
        const categoryNames = categories
            .filter(({ id }) => selectedCategories.includes(id))
            .map(({ name }) => name);

        confirm({
            title:
                `Do you really want to ${active ? "Deactivate" : "Activate"}  ${selectedCategories.length === 1 ? "Category" : "Categories"}: ${
                    categoryNames.join(", ")
                }?`,
            okText: "Yes",
            async onOk() {
                const mutations = selectedCategories.map((id) => {
                    
                    const input = {
                        id, 
                        deleted: active
                    };

                    return modifyContractTypeCategory({
                        variables: {
                            input
                        }
                    });
                });

                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${selectedCategories.length === 1 ? "Category" : "Categories"} successfully modified`
                });

                setSelectedCategories([]);
                refetch();
            }
        });
    };

    const createNewCategory = (
        <>
            <Modal
                title="Create Category"
                destroyOnClose={true}
                visible={createCategoryModal}
                okText="Save"
                onCancel={() => {
                    setNewCategoryName("");
                    setCreateCategoryModal(false);
                }}
                okButtonProps={{
                    disabled: !newCategoryName
                }}
                onOk={async (e) => {
                    e.preventDefault();

                    const input = {
                        name: newCategoryName, 
                        contractTypeId
                    };

                    await handleMutation(
                        createContractTypeCategory({
                            variables: {
                                input
                            }
                        })
                    );
                    
                    refetch();
                    setNewCategoryName("");
                    setCreateCategoryModal(false);
                }}
            >
                <EditableDetail
                    title="Category Name"
                    key="category-name-input"
                    placeholder={"Enter category name..."}
                    strict={true}
                    value={newCategoryName}
                    onValueUpdated={(e) => {
                        setNewCategoryName(e.target.value);
                    }}
                />
            </Modal>
            <Button
                style={{ float: "right" }}
                type="primary"
                className="ownLine"
                onClick={() => {
                    setNewCategoryName("");
                    setCreateCategoryModal(true);
                }}
            >
                Create Category 
            </Button>
        </>
    );

    const editCategory = (
        <>
            <Modal
                title={`Change Name of "${modifiedCategoryOldName}"`}
                destroyOnClose={true}
                visible={editCategoryModal}
                okText="Save"
                onCancel={() => {
                    setModifiedCategoryName(null);
                    setModifiedCategoryId("");
                    setModifiedCategoryOldName("");
                    setEditCategoryModal(false);
                }}
                okButtonProps={{
                    disabled: !modifiedCategoryName
                }}
                onOk={async (e) => {
                    e.preventDefault();

                    const input = {
                        name: modifiedCategoryName,
                        id: modifiedCategoryId, 
                        contractTypeId
                    };

                    const genuineModifications = getGenuineModifications(input, {name: modifiedCategoryOldName}, {
                        limitTo: ["name"],
                        trim: true
                    });

                    if (genuineModifications) {
                        await handleMutation(
                            modifyContractTypeCategory({
                                variables: {
                                    input
                                }
                            })
                        );
                        
                        setEditCategoryModal(false);
                        setModifiedCategoryName(null);
                        setModifiedCategoryId("");
                        setModifiedCategoryOldName("");
                        refetch();
                    }

                }}
            >
                <EditableDetail
                    title="Category Name"
                    placeholder={"Enter category name..."}
                    key="category-name-input"
                    strict={true}
                    value={modifiedCategoryName !== null ? modifiedCategoryName : modifiedCategoryOldName}
                    onValueUpdated={(e) => {
                        setModifiedCategoryName(e.target.value);
                    }}
                />
            </Modal>
        </>
    );

    const archiveCategoriesButton = <Button
        type="danger"
        size="small"
        style={{
            display: "block"
        }}
        onClick={showConfirmArchive}
        disabled={selectedCategories.length <= 0}
    >
        {active ? "Deactivate" : "Activate"} {selectedCategories.length} {selectedCategories.length === 1 ? "Category" : "Categories"}
    </Button>;

    const rightAlignedContent = <Radio.Group 
        defaultValue={active} 
        value={active}
        onChange={(e) => {
            setSelectedCategories([]);
            setActive(e.target.value);
        }} 
        buttonStyle="solid" 
        style={{
            margin: "20px 0 20px 0", 
            float: "right"
        }} 
        className="ownLine"
    >
        <Radio.Button value={true}> Active Categories </Radio.Button>
        <Radio.Button value={false}> Inactive Categories </Radio.Button>
    </Radio.Group>;

    const categoriesTable = (
        <div>
            <h2>{"Categories for Guidance Documents and Report Templates"}</h2>
            <Table 
                clearFiltersButton={archiveCategoriesButton}
                rightAlignedButtonBarElement={rightAlignedContent}
                columns={[
                    {
                        title: "Name",
                        dataIndex: "name",
                        defaultSortOrder: "ascend",
                        sortDirections: ["ascend", "descend", "ascend"],
                        key: "name",
                        sorter: safeSort("name")
                    }, 
                    (active && {
                        title: "Actions",
                        dataIndex: "id",
                        key: "edit-name",
                        render: (id, { name }) => {
                            return (
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        setModifiedCategoryId(id);
                                        setModifiedCategoryOldName(name);
                                        setEditCategoryModal(true);
                                    }}
                                >
                                    Edit Name
                                </Button>
                            );
                        }
                    })
                ].filter(col => col)}
                dataSource={categories} 
                rowKey={"id"}
                rowSelection={{
                    selectedRowKeys: selectedCategories,
                    onChange: (selectedRowKeys) => {
                        setSelectedCategories(selectedRowKeys);
                    }
                }}
            />
        </div>
    );

    return (
        <Main
            title={`${name ? `${name}  ` : ""}Library  Settings`}
            breadcrumbs={breadcrumbs}
            details={createNewCategory}
        >
            <div className="main-content-top-margin">
                {categoriesTable}
                {editCategory}
            </div>
        </Main>
    );
};

import React, { useState, useEffect } from "react";
import { Radio, Button } from "antd";
import { Table } from "../common/Table/index.js";
import { S3UploadStatus } from "../common/S3UploadStatus/index.js";

export const ReportTemplatesTable = ({
    reportTemplate
}) => {
    const [activeToggle, setActiveToggle] = useState(true);
    const [reportTemplateList, setReportTemplateList] = useState(reportTemplate?.versions);

    useEffect(() => {
        let reportList = reportTemplate?.versions;
        if (activeToggle) {
            setReportTemplateList(reportList.filter(({ reportTemplateStatus }) => reportTemplateStatus === "Active"));
        } else {
            setReportTemplateList(reportList.filter(({ reportTemplateStatus }) => reportTemplateStatus !== "Active"));
        }
    }, [activeToggle]);

    const rightAlignedRadioButtons =
        <Radio.Group
            defaultValue={activeToggle}
            value={activeToggle}
            onChange={(e) => {
                setActiveToggle(e.target.value);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true} >Active </Radio.Button>
            <Radio.Button value={false}>Inactive </Radio.Button>
        </Radio.Group>;

    const reportTemplateTable = (
        <Table
            size="middle"
            pagination={false}
            columns={[
                {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    width: "20%"
                },
                {
                    title: "Effective Date",
                    dataIndex: "startDate",
                    width: "15%",
                    render: (startDate, { endDate }) => {
                        return <>{startDate} {endDate && <span> - {endDate}</span>}</>;
                    }
                },
                {
                    title: "Version",
                    dataIndex: "versionNumber",
                    width: "10%"
                },
                {
                    title: "Upload Status",
                    dataIndex: "S3UploadStatus",
                    width: "10%",
                    render: S3UploadStatus
                },
                {
                    title: "Template Status",
                    dataIndex: "reportTemplateStatus",
                    key: "reportTemplateStatus",
                    width: "10%",
                },
                {
                    title: "Actions",
                    key: "library-documents-actions",
                    width: "10%",
                    render: () => { }
                }
            ]}
            dataSource={reportTemplateList}
        />
    );
    return (
        <>
            <div className="main-content-margin-top">
                <div style={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    float: "inline-end"
                }}>

                    <div style={{
                        float: "left"
                    }}>
                        <div style={{ display: "flex" }}>
                            <div><span style={{
                                fontSize: "16px",
                                fontWeight: "600"
                            }}>Report Templates</span></div>
                            <div style={{ marginLeft: "16px" }}>
                                <Button
                                    id="upload"
                                    type="primary"
                                >
                                    Upload
                                </Button>
                            </div>

                        </div>


                    </div>
                    <div style={{
                        float: "right"
                    }}>
                        {rightAlignedRadioButtons}
                    </div>
                </div>
                {reportTemplateTable}
            </div>
        </>

    );
};
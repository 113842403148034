import React, { useState, useEffect, useContext } from "react";
import { Pagination } from "antd";
import { FileTextTwoTone } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import { UserContext } from "../../Application/UserContext.js";
import { formatTimeValue, isUndefinedOrNull } from "../../../utils/functions.js";
import { searchable, displayFileSizeUnit } from "../../../utils/index.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { Table } from "../../common/Table/index.js";
import { PCDULink } from "../../common/PCDULink/index.js";
import { LibraryDocumentActions } from "../../common/LibraryDocumentActions/index.js";
import { DocumentUploadStatusActiveTracker } from "../../common/DocumentUploadStatusTracker/index.js";
import { mergeDocumentData } from "../_helpers.js";
import { getLibraryFolderContentsQuery, LibraryDocumentQuery } from "../queries.js";
import { UploadLibraryDocument } from "./upload-library-document.js";
import { CreateLibraryFolder } from "./actions/create-library-folder.js";
import { Subtitle } from "./subtitle.js";
import { ArchiveButton, ArchiveViewToggle } from "./actions/archive.js";
import { ButtonPanel } from "./button-panel.js";
import { useLocation } from "react-router";

const nullResult = <span> -- </span>;


export const FolderContentsTable = ({
    currentFolderId,
    setCurrentFolderId,
    currentFolderName,
    setCurrentFolderName,
    inArchivedFolder,
    baseFolderName,
    baseFolderId,
    contractTypeId,
    restrictToCategories,
    categories,
    canUploadDocuments,
    canCreateFolders,
    insideBulletinSubfolder
}) => { 
    const [sortOn, setSortOn] = useState("dateCreated");
    const [sortBy, setSortBy] = useState("descend");
    const [documentSelection, setDocumentSelection] = useState([]);
    const [documentsPage, setDocumentsPage] = useState(1);
    const [documentsPageSize, setDocumentsPageSize] = useState(10);
    const [documentNameSearch, setDocumentNameSearch] = useState("");
    const [activeFilter, setActiveFilter] = useState(!inArchivedFolder);
    const [updatedDocuments, setUpdatedDocuments] = useState([]);

    const resetStateOnFolderChange = (active) => {
        setDocumentsPage(1);
        setDocumentsPageSize(10);
        setActiveFilter(active);
        setDocumentSelection([]);
        setDocumentNameSearch("");
        setSortOn("dateCreated");
        setSortBy("descend");
    };

    const offset = Math.max(0, ((documentsPage - 1) * documentsPageSize));

    const { data, previousData, error, loading, refetch } = useQuery(getLibraryFolderContentsQuery, {
        variables: {
            folderId: currentFolderId, 
            contractTypeId,
            nameSearch: documentNameSearch ?? "",
            activeFilter,
            sortOn,
            sortBy,
            offset,
            limit: documentsPageSize 
        },
        fetchPolicy: "no-cache"
    }); 


    useEffect(() => {
        setActiveFilter(!inArchivedFolder);
    }, [inArchivedFolder]); 

    useEffect(() => {
        setUpdatedDocuments([]);
    }, [data]);

    const {
        userPermDownloadLibDoc,
        userPermDownloadAssociatedLibDoc,
        userPermUploadnModifyLibDoc,
        userIsSubmitter
    } = useContext(UserContext);

    const location = useLocation();
    const isReportTemplatesPage = location.pathname.includes("Report");

    // normalize fetched library documents w/ any updates since last refetch :: 
    const libraryDocuments = loading 
        ? (previousData?.libraryFolderContents ?? [])
        : mergeDocumentData(data?.libraryFolderContents, updatedDocuments); 
    const documentCount = libraryDocuments[0]?.count ?? 0;

    const userCanDownloadDocuments = userPermDownloadLibDoc || userPermDownloadAssociatedLibDoc;

    // don't display category selection options for folders that already exist :: 
    const validCategoryOptions = categories.filter(category => !libraryDocuments.some(({ name, isFolder }) => isFolder && name === category.name));

    if (error) {
        return handleGraphQLError(error);
    }
            
    return (
        <div>
            <Subtitle text={currentFolderName} isArchived={inArchivedFolder} />
            <ButtonPanel>
                { canCreateFolders 
                    ? <CreateLibraryFolder 
                        afterFolderCreation={() => {
                            resetStateOnFolderChange(true);
                            refetch();
                        }}
                        disabled={activeFilter !== true}
                        restrictToCategories={restrictToCategories}
                        categories={validCategoryOptions}
                        contractTypeId={contractTypeId}
                        baseFolderName={baseFolderName}
                        baseFolderId={baseFolderId}
                        currentFolderId={currentFolderId}
                    />  
                    : null 
                }
                { canUploadDocuments 
                    ? <UploadLibraryDocument 
                        afterUpload={() => {
                            resetStateOnFolderChange(true);
                            refetch();
                        }}
                        disabled={activeFilter !== true}
                        currentFolderId={currentFolderId}
                        baseFolderName={baseFolderName} 
                        contractTypeId={contractTypeId}
                    /> 
                    : null 
                }
            </ButtonPanel>
            <Table 
                buttonBarStyle={{ marginBottom: "15px" }}
                clearFiltersButton={userPermUploadnModifyLibDoc 
                    ? <ArchiveButton 
                        refetch={refetch}
                        documentSelection={documentSelection}
                        setDocumentSelection={setDocumentSelection}
                        activeFilter={activeFilter}
                        inArchivedFolder={inArchivedFolder}
                        libraryDocuments={libraryDocuments}
                    />
                    : null
                }
                rightAlignedButtonBarElement={<ArchiveViewToggle 
                    defaultValue={activeFilter} 
                    value={activeFilter}
                    onChange={(e) => {
                        setActiveFilter(e.target.value);
                        setDocumentsPage(1);
                    }}
                />}
                loading={loading} 
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    searchable({
                        title: `Name${isReportTemplatesPage ? " (First Version)" : ""}`,
                        dataIndex: "name",
                        key: "name",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        searchedText: documentNameSearch,
                        handleSearch: setDocumentNameSearch,
                        setPage: setDocumentsPage,
                        handleReset: () => setDocumentNameSearch(""), 
                        render: (name, { id, isFolder, active, name: folderName }) => {
                            return isFolder 
                                ? <PCDULink
                                    preventDefault={false}
                                    key={id}
                                    to={{}}
                                    onClick={() => {
                                        resetStateOnFolderChange(active);
                                        setCurrentFolderName(folderName);
                                        setCurrentFolderId(id);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />
                                    {name}
                                </PCDULink>
                                : <PCDULink to={`/library/documents/${id}`}>
                                    <FileTextTwoTone style={{ marginRight: "10px" }} />
                                    { name }
                                </PCDULink>;
                        }
                    }),
                    insideBulletinSubfolder 
                        ? {
                            title: "Type",
                            dataIndex: "isFolder",
                            key: "isFolder",
                            sortDirections: ["ascend", "descend", "ascend"],
                            sorter: true,
                            render: (isFolder) => isFolder ? "Folder" : "File"
                        } 
                        : null,
                    {
                        title: "Size",
                        dataIndex: "fileSize",
                        key: "fileSize",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (fileSize, { isFolder }) => {
                            return isFolder ? nullResult : displayFileSizeUnit(fileSize);
                        }
                    },
                    !isReportTemplatesPage && {
                        title: "Date Created",
                        dataIndex: "dateCreated",
                        key: "dateCreated",
                        sorter: true,
                        defaultSortOrder: "descend",
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (dateCreated) => {
                            return isUndefinedOrNull(dateCreated)
                                ? nullResult
                                : <span>{formatTimeValue(dateCreated, true)}</span>;
                        }
                    },
                    isReportTemplatesPage && {
                        title: "Last Modified",
                        dataIndex: "dateModified",
                        key: "dateModified",
                        sorter: true,
                        defaultSortOrder: "descend",
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (dateModified) => {
                            return isUndefinedOrNull(dateModified)
                                ? nullResult
                                : <span>{formatTimeValue(dateModified, true)}</span>;
                        }
                    },
                    {
                        title: "Author",
                        key: "author-name",
                        dataIndex: "authorName",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, record) => {
                            const nameToUse = record?.author?.name ?? name;
                            return !nameToUse ? nullResult : <span>{ nameToUse }</span>;
                        }
                    },
                    isReportTemplatesPage && {
                        title: "Archived",
                        key: "active",
                        dataIndex: "active",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (active) => {
                            return active ? "No" : "Yes";
                        }
                    },
                    {
                        title: "Upload Status",
                        key: "uploadStatus",
                        dataIndex: "S3UploadStatus",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (S3UploadStatus, { id, fileSize, isFolder }) => {
                            return isFolder 
                                ? nullResult 
                                : <DocumentUploadStatusActiveTracker
                                    status={S3UploadStatus}
                                    fileSize={fileSize}
                                    fetchOptions={{
                                        variables: { 
                                            id, 
                                            includeS3Metadata: false 
                                        }, 
                                        fetchPolicy: "no-cache"
                                    }}
                                    query={LibraryDocumentQuery}
                                    documentTypeName={"libraryDocument"}
                                />;
                        }
                    },
                    (!userCanDownloadDocuments ? null : !isReportTemplatesPage && {
                        title: "Actions",
                        key: "library-documents-actions",
                        render: (_, record) => record.isFolder ? null : <LibraryDocumentActions 
                            isSubmitter={userIsSubmitter}
                            record={record}
                            userCanUpload={userPermUploadnModifyLibDoc}
                            libraryFolderId={currentFolderId}
                            reportUpdatedDocument={(updatedDocument) => { 
                                const updatedDocumentToUse = updatedDocument && typeof updatedDocument === "object" ? updatedDocument : {};
                                const latestDocuments = mergeDocumentData(libraryDocuments, [updatedDocumentToUse]); 
                                setUpdatedDocuments(latestDocuments);
                            }} 
                            onReuploadFailure={() => {
                                refetch().finally(() => setUpdatedDocuments([]));
                            }}
                        />
                    })
                ].filter(col => col))}
                dataSource={libraryDocuments}
                rowKey="id"
                pagination={false}
                rowSelection={(userPermUploadnModifyLibDoc && !inArchivedFolder) && {
                    selectedRowKeys: documentSelection,
                    onChange: (selectedRowKeys) => {
                        setDocumentSelection(selectedRowKeys);
                    }
                }}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onShowSizeChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                onChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                current={documentsPage}
                defaultCurrent={documentsPage}
                pageSize={documentsPageSize}
                defaultPageSize={documentsPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={documentCount}
            />
        </div>
    );  
};
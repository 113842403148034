import React from "react";
import { DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export const DateableDetail = ({
    title,
    defaultValue,
    value,
    disabledDate,
    onValueUpdated,
    required = true,
    readOnly = false,
    isReportTemplate = false
}) => {
    const classNames = "input input--sae input--filled";

    const label = (
        <label className="input__label input__label--sae">
            <FontAwesomeIcon icon={faPencilAlt} color="#f00" className="icon--sae" />
            <span className="input__label-content input__label-content--sae">
                {title}
            </span>
        </label>
    );

    const input = (
        <DatePicker
            allowClear={!required}
            defaultValue={defaultValue}
            value={value ? value : null}
            format={"MM/DD/YYYY"}
            className={isReportTemplate ? "" : "input__field input__field--sae input__field--datepicker"}
            disabledDate={disabledDate}
            onChange={(newDateMoment) => {
                onValueUpdated(newDateMoment);
            }}
            disabled={readOnly}
        />
    );

    return (
        <div className={classNames}>
            {input}
            {label}
        </div>
    );
};
